<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-alert outlined type="info" prominent border="left" class="my-10">
          Téléchargez le
          <a href @click.prevent="downloadReport">rapport</a> complet des
          rendez-vous.
        </v-alert>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="text-center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="bookings"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          class="elevation-1"
          locale="fr-CH"
          :footer-props="{
            itemsPerPageOptions: [10, 30, 50],
            itemsPerPageText: 'Élements par page',
          }"
        >
          <template v-slot:body="{ items }">
            <tbody class="text-left">
              <tr v-for="item in items" :key="item._id">
                <td>{{ format(parseISO(item.date), "dd.MM.yyyy") }}</td>
                <td>{{ format(parseISO(item.date), "HH:mm") }}</td>
                <td>{{ item.site }}</td>
                <td>{{ item.employee.lastname }}</td>
                <td>{{ item.employee.firstname }}</td>
                <td>
                  <a :href="`mailto:${item.employee.email}`">{{
                    item.employee.email
                  }}</a>
                </td>
                <td>
                  {{ format(parseISO(item.updatedAt), "dd.MM.yyyy HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
          <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
            >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
          >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import fileDownload from "js-file-download";
import { format, parseISO } from "date-fns";

export default {
  name: "AdminPart",
  data: () => ({
    bookings: [],
    options: {},
    total: 0,
    loading: false,
    headers: [
      {
        text: "Date",
        sortable: false,
      },
      {
        text: "Heure",
        sortable: false,
      },
      {
        text: "Site",
        sortable: false,
      },
      {
        text: "Nom",
        sortable: false,
      },
      {
        text: "Prénom",
        sortable: false,
      },
      {
        text: "E-mail",
        sortable: false,
      },
      {
        text: "Date réservation",
        sortable: false,
      },
    ],
    parseISO,
    format,
  }),
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/bookings?page=${page}&itemsPerPage=${itemsPerPage}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      return {
        items,
        total,
      };
    },
    async downloadReport() {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/bookings/export`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "bookings.xlsx");
    },
  },
  watch: {
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.bookings = data.items;
        this.total = data.total;
      },
      deep: true,
    },
  },
};
</script>
